import React from "react"

const Partners = () => {
   
    const partners = [
        {
            key:'hmd-us', 
            img: "https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fhmd-us.png?alt=media&token=59787671-5c76-4760-be2f-ad1ad3c09e47",
            title: "HMD U.S.A.",
            link:"http://warrentontkd.com/" 
        },
        {
            key:'hmd-grece',
            img: "https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fhmd-greece.jpg?alt=media&token=053cd522-c3a0-4d31-96d9-9bc9a8aa11f0",
            title: "HMD Greece",
            link:"http://www.greek-martial-arts.gr/"
         },        
        {
            key:'hmd-switzerland',
            img: "https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Fhmd-switzerland.png?alt=media&token=0f13ef30-bd2d-4116-b4a0-006eac135563",
            title: "HMD Switzerland",
            link:"https://www.hmdbasel.ch"
         },
        {
            key:'topten',
            img: "https://firebasestorage.googleapis.com/v0/b/hmd-website.appspot.com/o/general%2Frsz_top_ten_bianco.png?alt=media&token=a09985f6-f178-4647-aea1-18698e27e0b2",
            title: "TOP TEN",
            link:"https://www.topten-italia.com/"
         },
        
    ]


    return (
        <div className="mx-auto max-w-screen-xl py-10">
            <div className="flex justify-center flex-wrap ">
                {partners.map((partner, index) => {
                    return(
                    <div key={`partner-${index}`} className="w-full md:w-auto text-center mx-10 my-auto">
                        {partner.img && 
                            <a href={partner.link} className="cursor-pointer" target="_blank" rel="noreferrer">
                                <img className="w-20 mx-auto opacity-50 hover:opacity-100" src={partner.img} alt={partner.title} />
                                <span className="text-xs text-gray-500">{partner.title}</span>
                            </a>
                        }
                    </div>
                    )})
                }
               
            </div>
        </div>
      
    )
}

export default Partners;